import { LoginComponent } from "@components/LoginToView/LoginComponent/LoginComponent";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { Avatar } from "@jmc/solid-design-system/src/components/atoms/Avatar/Avatar";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Icon, jnjChevronDown, jnjChevronUp, jnjPerson } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Spinner } from "@jmc/solid-design-system/src/components/atoms/Spinner/Spinner";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Menu, MenuItem } from "@jmc/solid-design-system/src/components/molecules/Menu/Menu";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery";
import urlHelper from "@jmc/solid-design-system/src/utils/url-helper";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { default as path } from "@jmc/utils/utils/path";
import {
    mdiAccount,
    mdiAccountCircleOutline,
    mdiAccountOutline,
    mdiCheckCircleOutline,
    mdiChevronDown,
    mdiCircleOutline,
} from "@mdi/js";
import { State } from "@redux/create-api-module";
import { ApplicationState } from "@redux/modules";
import { ValidAuth } from "@redux/modules/authValid";
import { clear } from "@redux/modules/materialCollection";
import { Profile } from "@redux/modules/profile";
import { AuthProviders, LoginTypes } from "@types";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import yn from "yn";

import { imitate, logout, logoutAnonymously } from "../../../auth/auth";
import style from "./style.module.scss";

export const AuthHeaderItemForExport = (): JSX.Element => {
    const { t } = useTranslation();
    const locale = useLocale();
    const authData = useSelector((state: ApplicationState) => state.authValid as unknown as State, isEqual);
    const profile = useSelector((state: ApplicationState) => state.profile?.data as unknown as Profile, isEqual);

    const getAuthResult = (): ValidAuth | null => {
        return (
            authData?.data?.results?.find((v: { provider: AuthProviders }) => {
                return [
                    AuthProviders.hcp,
                    AuthProviders.okta_hcp,
                    AuthProviders.ping,
                    AuthProviders.marketing,
                    AuthProviders.anonymous,
                ].includes(v.provider);
            }) || null
        );
    };

    const [authResult, setAuthResult] = useState(getAuthResult());
    const [showLogin, setShowLogin] = useState(false);
    const [isSwitchingUser, setIsSwitchingUser] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const isMobile = useMediaQuery(BreakPoint.md);
    const shiftClicked = useRef(false);
    const defaultProvider = yn(process.env.GATSBY_CIAM2_AS_DEFAULT) ? AuthProviders.okta_hcp : AuthProviders.hcp;
    const shiftClickProvider = yn(process.env.GATSBY_CIAM2_AS_DEFAULT) ? AuthProviders.hcp : AuthProviders.okta_hcp;
    const dispatch = useDispatch();
    const { jnjFullBranded } = useJnjBranding();

    useEffect(() => {
        setAuthResult(getAuthResult());
    }, [authData]);

    const handleMenuStatus = (status: boolean): void => {
        setIsMenuOpened(status);
    };

    const handleKeyPress = (
        event: React.KeyboardEvent,
        customEnter?: (e?: React.KeyboardEvent) => void | Promise<void>,
    ): void => {
        if (event.key === "Enter") {
            if (customEnter) {
                customEnter();
            } else {
                setOpenMenu(true);
            }
        } else if (event.key === "Escape") {
            setOpenMenu(false);
        }
    };

    const trigger = (
        <div
            data-test-id="AuthHeaderItem"
            className={style.authHeaderItem}
            id="auth_header_item"
            tabIndex={0}
            onKeyDown={handleKeyPress}
        >
            <div className={style.avatar}>
                <Avatar
                    className={jnjFullBranded ? style.avatar__container : null}
                    name={
                        !profile?.firstName && !profile?.lastName ? null : `${profile?.firstName} ${profile?.lastName}`
                    }
                    icon={!profile?.firstName && !profile?.lastName ? (jnjFullBranded ? jnjPerson : mdiAccount) : null}
                    iconType={jnjFullBranded ? "jnj" : "mdi"}
                    size={jnjFullBranded ? "medium" : "small"}
                />
                <div className={style.avatar__icon}>
                    {jnjFullBranded ? (
                        <Icon
                            icon={isMenuOpened ? jnjChevronUp : jnjChevronDown}
                            color="black"
                            size="0.75rem"
                            type="jnj"
                            verticalAlignMiddle
                        />
                    ) : (
                        <Icon icon={mdiChevronDown} size="small" color="inherit" />
                    )}
                </div>
            </div>
        </div>
    );

    const switchUser = async (id: string): Promise<void> => {
        if (isSwitchingUser) return;
        setIsSwitchingUser(true);
        await imitate(id);
        setIsSwitchingUser(false);
    };

    const goToProfilePage = (): void => {
        window.location.assign(
            urlHelper.getUrl(path.join(urlHelper.getApiBaseUrl(), "/page/profile"), locale, false, {
                redirectUrl: process.env.GATSBY_APP_DOMAIN,
            }),
        );
    };

    if (authData?.loading || (!authData?.data && !authData?.error)) {
        return null;
    }

    if (authResult?.provider === AuthProviders.ping && authResult?.loginType === LoginTypes.PING) {
        const availableCodsIds = JSON.parse(process.env.GATSBY_IMITATION_USERS) || [];
        const imitationUsers = (): JSX.Element => {
            return (
                <>
                    <div className={classnames(style.profileItem, style.listItem)}>
                        <Avatar icon={mdiAccount} size="small" />

                        <div style={{ flexGrow: 1 }}>
                            <Typography style={{ alignSelf: "center" }} weight="600" size={isMobile ? "default" : "s"}>
                                {profile?.codsId}
                            </Typography>
                            <Typography size={isMobile ? "s" : "xs"}>
                                {availableCodsIds[profile?.codsId]?.description}
                            </Typography>
                        </div>
                    </div>
                    <div className={style.listItem}>
                        <Icon
                            icon={mdiAccountCircleOutline}
                            color="grey-light"
                            size="xs"
                            style={{ display: "inlineFlex" }}
                        ></Icon>
                        <Typography size={isMobile ? "default" : "s"}>{t("Profiles", { ns: "common" })}</Typography>
                    </div>
                    {Object.keys(availableCodsIds).map((id, index) => {
                        const isLoggedIn = id == profile?.codsId;
                        return (
                            <div
                                key={`codsId-${index}`}
                                style={{
                                    cursor: isSwitchingUser ? "wait" : "pointer",
                                }}
                                tabIndex={0}
                                onKeyDown={async (event) => handleKeyPress(event, () => switchUser(id))}
                                onClick={async () => {
                                    switchUser(id);
                                }}
                                className={classnames(style.userOption, style.listItem)}
                            >
                                <div style={{ flexGrow: 1 }}>
                                    <Typography weight="600" size={isMobile ? "default" : "s"}>
                                        {id}
                                    </Typography>
                                    <Typography size={isMobile ? "s" : "xs"}>
                                        {availableCodsIds[id]?.description}
                                    </Typography>
                                </div>
                                <Icon
                                    icon={isLoggedIn ? mdiCheckCircleOutline : mdiCircleOutline}
                                    color={isLoggedIn ? "secondary" : "grey-light"}
                                    size="xs"
                                ></Icon>
                            </div>
                        );
                    })}
                </>
            );
        };

        return (
            <div className={style.element}>
                <Menu
                    trigger={trigger}
                    data-test-id="AuthHeaderItem.Menu"
                    disableFullscreen
                    displaySeparator
                    openMenu={openMenu}
                    placement="bottom-end"
                >
                    {availableCodsIds && imitationUsers()}
                    <MenuItem
                        data-test-id="AuthHeaderItem.MenuItem.Logout"
                        id="logout"
                        onClick={async (): Promise<void> => {
                            await dispatch(clear());
                            logout({
                                provider: AuthProviders.ping,
                            });
                        }}
                        onKeyDown={async (event) =>
                            handleKeyPress(event, async () => {
                                await dispatch(clear());
                                logout({
                                    provider: AuthProviders.ping,
                                });
                            })
                        }
                    >
                        {t("Logout", { ns: "common" })}
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    if (
        [AuthProviders.hcp, AuthProviders.okta_hcp].includes(authResult?.provider) &&
        authResult?.loginType !== LoginTypes.EA_TOKEN
    ) {
        return (
            <div className={style.element}>
                <Menu
                    trigger={trigger}
                    data-test-id="AuthHeaderItem.Menu"
                    placement="bottom-end"
                    openMenu={openMenu}
                    disableFullscreen
                    displaySeparator={!jnjFullBranded}
                    setMenuStatus={handleMenuStatus}
                >
                    <MenuItem
                        data-test-id="AuthHeaderItem.MenuItem.Profile"
                        id="profile"
                        onClick={goToProfilePage}
                        onKeyDown={(event: React.KeyboardEvent) => handleKeyPress(event, goToProfilePage)}
                    >
                        {t("Profile", { ns: "common" })}
                    </MenuItem>
                    {authResult?.loginType !== LoginTypes.ANONYMOUS_LOGIN && (
                        <MenuItem
                            data-test-id="AuthHeaderItem.MenuItem.Logout"
                            id="logout"
                            onClick={async (): Promise<void> => {
                                await dispatch(clear());
                                logout({ provider: authResult?.provider });
                            }}
                            onKeyDown={(event: React.KeyboardEvent) =>
                                handleKeyPress(event, async () => {
                                    await dispatch(clear());
                                    logout({ provider: authResult?.provider });
                                })
                            }
                        >
                            {t("Logout", { ns: "common" })}
                        </MenuItem>
                    )}
                    {/* TESTING PURPOSES */}
                    {process.env.NODE_ENV === "development" && authResult?.loginType === LoginTypes.ANONYMOUS_LOGIN && (
                        <MenuItem
                            data-test-id="AuthHeaderItem.MenuItem.LogoutAnonymously"
                            id="logout-anonymously"
                            onClick={async (): Promise<void> => {
                                await dispatch(clear());
                                logoutAnonymously();
                            }}
                            onKeyDown={(event: React.KeyboardEvent) =>
                                handleKeyPress(event, async () => {
                                    await dispatch(clear());
                                    logoutAnonymously();
                                })
                            }
                        >
                            {t("Logout", { ns: "common" })}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    }

    const handleLogin = (event: React.KeyboardEvent | React.MouseEvent): void => {
        shiftClicked.current = event.getModifierState("Shift");
        setShowLogin(true);
    };

    return (
        !yn(process.env.GATSBY_LOGIN_DISABLED) && (
            <>
                {isMobile && jnjFullBranded ? (
                    <button
                        className={style.signInMobile}
                        id="login"
                        aria-label={t("Sign in", { ns: "common" })}
                        onClick={handleLogin}
                        onKeyDown={(event) => handleKeyPress(event, handleLogin)}
                        data-test-id="Header.SignIn"
                    >
                        <Avatar className={style.avatar__container} icon={jnjPerson} iconType="jnj" size={"medium"} />
                    </button>
                ) : (
                    <Button
                        id="login"
                        size="small"
                        data-test-id="Header.SignIn"
                        onClick={handleLogin}
                        aria-label={t("Sign in", { ns: "common" })}
                        onKeyDown={(event) => handleKeyPress(event, handleLogin)}
                        accessoryLeft={
                            showLogin ? (
                                <Spinner size="small" color="white" />
                            ) : jnjFullBranded ? (
                                <Icon icon={jnjPerson} color="white" type="jnj" size="small" verticalAlignMiddle />
                            ) : (
                                <Icon icon={mdiAccountOutline} color="white" size="xs" verticalAlignMiddle />
                            )
                        }
                        flexibleWidth={jnjFullBranded}
                    >
                        {t("Sign in", { ns: "common" })}
                    </Button>
                )}
                {showLogin && (
                    <LoginComponent
                        value={"Login"}
                        onCancel={() => {
                            setShowLogin(false);
                            shiftClicked.current = false;
                        }}
                        provider={shiftClicked.current ? shiftClickProvider : defaultProvider}
                    />
                )}
            </>
        )
    );
};

export const AuthHeaderItem = React.memo(AuthHeaderItemForExport);
