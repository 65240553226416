import React, { ReactNode } from "react";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";

import style from "./style.module.scss";
import classnames from "classnames";
import cleanCSSIdentifier from "@jmc/utils/utils/clean-css-identifier";
import useAbsoluteUnits from "@jmc/solid-design-system/src/utils/useAbsoluteUnits";

export enum Size {
    AUTO = "auto",
    "X-Small" = "X-Small",
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
}

export enum Variant {
    CONTENT = "content",
    MEDIA_SMALL = "mediaSmall",
    MEDIA_LARGE = "mediaLarge",
}

export enum Color {
    WHITE = "white",
    PRIMARY = "primary",
    GRAY = "gray",
    DEFAULT = Color.WHITE,
}

export interface Props {
    id: string;
    title: string;
    titleComponent?: JSX.Element | string;
    content?: JSX.Element | string;
    actions?: JSX.Element | JSX.Element[];
    image?: JSX.Element;
    children?: ReactNode;
    variant?: Variant;
    size?: Size;
    color?: Color;
    sidesColor?: Color;
    hideHeroSeparator?: boolean; // TODO: Remove when AHAY-5988 is solved
}

/**
 * Rebranded version of the old Hero component.
 *
 * This type of hero is only used in the page headers.
 * Comes from Hero content type in the CMS
 */
export const HeaderHero = (props: Props): JSX.Element => {
    const {
        id,
        title,
        titleComponent,
        content,
        actions,
        image,
        size = Size.AUTO,
        variant = Variant.CONTENT,
        color,
        sidesColor = Color.DEFAULT,
        hideHeroSeparator,
        children,
    } = props;

    let finalVariant = variant ?? Variant.CONTENT;
    if (finalVariant === Variant.CONTENT && !image) {
        finalVariant = Variant.MEDIA_LARGE;
    }

    let finalColor = Color.DEFAULT;
    if (Object.values(Color).includes(color)) {
        finalColor = color;
    }
    if (finalVariant === Variant.CONTENT && !color) {
        finalColor = Color.GRAY;
    }

    // These are used in JMC for printed reports
    const absoluteUnits = useAbsoluteUnits();

    return (
        <div
            className={classnames(
                style.hero,
                style[`variant__${finalVariant}`],
                style[`color__${finalColor}`],
                sidesColor ? style[`sides__${sidesColor}`] : style[`sides__${Color.DEFAULT}`],
                hideHeroSeparator ? style["hide-separator"] : undefined,
            )}
            data-test-id="Hero"
            id={`Hero.${cleanCSSIdentifier(id?.toLowerCase())}`}
        >
            <div className={style.wrapper}>
                <div className={classnames(style.body)}>
                    <div className={style.title} data-test-id="Hero.Title">
                        <Typography variant="h1" color="inherit">
                            {titleComponent || title}
                        </Typography>
                    </div>
                    {(content || children) && (
                        <div className={style.content} data-test-id="Hero.Content">
                            <Typography size="xl" color="inherit">
                                {content || children}
                            </Typography>
                        </div>
                    )}
                    <div className={style.actions} data-test-id="Hero.Actions">
                        {actions}
                    </div>
                </div>

                {image && (
                    <div
                        className={classnames(
                            style.media,
                            absoluteUnits ? style.absolute : null,
                            style[`size__${size}`],
                        )}
                        data-test-id="Hero.Media"
                    >
                        {image}
                    </div>
                )}
            </div>
        </div>
    );
};
