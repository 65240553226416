import { ArticleOverview } from "@components/ArticleOverview/ArticleOverview";
import { CourseOverview } from "@components/Course/CourseOverview/CourseOverview";
import { EventOverview } from "@components/Event/EventOverview/EventOverview";
import { JMCCarousel } from "@components/JMCCarousel/JMCCarousel";
import { MaterialOverview } from "@components/Material/MaterialOverview/MaterialOverview";
import { MediaOverview } from "@components/MediaOverview/MediaOverview";
import OneTrustWebForm from "@components/OneTrustWebForm/OneTrustWebForm";
import { PodcastOverview } from "@components/PodcastOverview/PodcastOverview";
import { ProductOverview } from "@components/ProductOverview/ProductOverview";
import { PubmedSearch } from "@components/PubmedSearch/PubmedSearch";
import { ScientificPublicationOverview } from "@components/ScientificPublicationOverview/ScientificPublicationOverview";
import { default as GatsbyImageCard } from "@jmc/core/src/components/GatsbyImageCard/GatsbyImageCard";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { ItemPosition, Sizes } from "@jmc/solid-design-system/src/components/atoms/Grid/Grid";
import { ImagePosition } from "@jmc/solid-design-system/src/components/atoms/Hero/enum/ImagePosition";
import {
    ContentAlignment,
    ContentVerticalAlignment,
    Hero,
} from "@jmc/solid-design-system/src/components/atoms/Hero/Hero";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { ImagePlacement } from "@jmc/solid-design-system/src/components/molecules/Card/Card";
import { Color, Size, Variant } from "@jmc/solid-design-system/src/components/molecules/HeaderHero/HeaderHero";
import { Heading } from "@jmc/solid-design-system/src/components/molecules/Heading/Heading";
import { Tabs } from "@jmc/solid-design-system/src/components/molecules/Tabs/Tabs";
import { cleanCSSIdentifier } from "@jmc/utils/utils/clean-css-identifier";
import fileHelper from "@jmc/utils/utils/file-helper";
import jsonMapper from "@jmc/utils/utils/json-mapper";
import stripSlash from "@jmc/utils/utils/strip-slash";
import {
    AccessLevel,
    CarouselAlignContent,
    CarouselAlignContentVertically,
    CarouselBackgroundColor,
    CarouselTextColor,
    CarouselViewMode,
    CMS_CARD_VARIANT_BACKGROUND,
    CMS_MEDICAL_CONTENT,
    CMS_PROMOTIONAL_CONTENT,
    CMSAccordionProps,
    CMSArticleOverviewProps,
    CMSButtonProps,
    CMSCardProps,
    CMSCarouselProps,
    CMSCourseOverviewProps,
    CMSEventOverviewProps,
    CMSHeadingProps,
    CMSHeroProps,
    CMSImageProps,
    CMSLinkGroupProps,
    CMSMapProps,
    CMSMaterialOverviewProps,
    CMSMediaOverviewProps,
    CMSOnetrustFormProps,
    CMSPage,
    CMSPodcastOverviewProps,
    CMSProductOverviewProps,
    CMSPubmedSearchProps,
    CMSRegulatoryStatus,
    CMSScientificPublicationOverviewProps,
    CMSSectionProps,
    CMSTabsProps,
    CMSTypographyProps,
    RestrictedPageByLinkKeys,
} from "@types";
import { getInternalPageLinkUrl } from "@utils/menu-helper";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { AccordionProps } from "types/CMSAccordionProps";
import { CMSAudienceProps } from "types/CMSAudienceProps";

import { Variants } from "../../../../design_system/src/components/atoms/Button/Button";

const variantMap = new Map<string, string>([
    ["Outlined", "outlined"],
    ["Contained", "contained"],
    ["Naked", "naked"],
]);

const colorMap = new Map<string, Colors>([
    ["accent 1", "accent"],
    ["accent 2", "accent-2"],
    ["accent", "accent"],
    ["dark", "dark"],
    ["error color", "error"],
    ["gray", "gray"],
    ["grey color", "grey"],
    ["light grey", "gray"],
    ["primary color", "primary"],
    ["primary", "primary"],
    ["secondary color", "secondary"],
    ["secondary", "secondary"],
    ["success color", "success"],
    ["transparent color", "transparent"],
    ["warning color", "warning"],
    ["white", "white"],
]);

export const calloutColorMap = new Map<string, CardColor>([
    ["primary", "primary"],
    ["secondary", "primary"],
    ["white", "white"],
    ["dark", "primary"],
    ["accent 1", "white"],
    ["accent 2", "white"],
    ["gray", "gray"],
]);

export const rebrandedCardBackgroundColorMap = new Map<string, Colors>([
    ["white", "white"],
    ["gray", "gray"],
    ["transparent", "transparent"],
]);

// TODO: find a better way to avoid duplication of types / literals across design system and here...
type Colors =
    | "primary"
    | "secondary"
    | "accent"
    | "accent-2"
    | "success"
    | "warning"
    | "error"
    | "grey"
    | "gray"
    | "transparent"
    | "white";

const mapBackgroundColors = (
    cmsColor: "primary" | "secondary" | "accent 1" | "Accent 1" | "accent 2" | "Accent 2" | "white" | "dark",
    defaultColor = "primary",
): Colors => colorMap.get(cmsColor?.toLowerCase()) || (defaultColor as Colors);

const mapBrandedBackgroundColors = (cmsColor: Colors, defaultColor = "transparent"): Colors =>
    rebrandedCardBackgroundColorMap.get(cmsColor) || (defaultColor as Colors);

const mapColors = (
    cmsColor: "primary" | "secondary" | "accent 1" | "Accent 1" | "accent 2" | "Accent 2" | "white" | "dark",
    defaultColor = "primary",
): Colors => colorMap.get(cmsColor?.toLowerCase()) || (defaultColor as Colors);

const mapCalloutColors = (cmsColor: CMSColor): HeroColor => {
    return calloutColorMap.get(cmsColor?.toLowerCase());
};

const mapButtonColors = (cmsColor: "Primary Color" | "Secondary Color" | "Accent 1" | "Accent 2" | "White"): Colors =>
    colorMap.get(cmsColor?.toLowerCase()) || "primary";

const sizeMap = new Map<string, string>([
    ["Small", "small"],
    ["Medium", "medium"],
    ["Large", "large"],
]);

// These pages have a L3 restriction
// They are needed to set the access level to L3 when mapping the button props
const restrictedL3PageLinkTypeList: RestrictedPageByLinkKeys[] = [
    "ask_the_experts_uid",
    "event_center_uid",
    "event_id",
    "lms_uid",
    "myjmc_id",
    "request_materials_uid",
    "scientific_publications_uid",
    "transfer_of_value_uid",
];
export interface GridSizes {
    xs?: Sizes;
    sm?: Sizes;
    md?: Sizes;
    lg?: Sizes;
    xl?: Sizes;
}
export interface LinkProps {
    external: boolean;
    commercial: boolean;
    access_level: AccessLevel;
    url: string;
    anchor_id?: string;
    params?: { [key: string]: string };
    isFileAsset?: boolean;
}
interface MapButtonProps {
    buttonProps: React.ComponentProps<typeof Button>;
    link: LinkProps;
    fileUrl: string;
}

type CMSMenuItem = {
    menu_item: {
        label: string;
        icon: string;
        link: {
            url: string;
            regulatory_status: CMSRegulatoryStatus;
        }[];
        external_link: {
            href: string;
        };
    };
};

type MenuItem = {
    menu_item: {
        icon: string;
        label: string;
        link: { url: string };
    };
};

interface onetrustPageReference extends CMSPage {
    page_url: string;
}

export interface CardButtons extends MapButtonProps {
    text: string;
}

const mapButtonVariants = (cmsVariant: "Outlined" | "Contained" | "Naked"): string => {
    return variantMap.get(cmsVariant) || "contained";
};

const mapHeadingSizes = (cmsSize: "Small" | "Medium" | "Large"): string => {
    return sizeMap.get(cmsSize) || "medium";
};

export const getLink = (linkGroup: CMSLinkGroupProps, return404 = true): LinkProps => {
    if (linkGroup?.external_link) {
        if (linkGroup?.external_link === "/login" || linkGroup?.external_link === "/register") {
            return {
                external: false,
                commercial: true,
                url: linkGroup.external_link,
            };
        }

        return {
            external: true,
            commercial: true,
            url: linkGroup.external_link,
        };
    } else if (linkGroup?.internal_link?.length > 0 || linkGroup?.link_continued) {
        let params: string;
        linkGroup.internal_link_parameters?.value?.forEach(({ key, value }) => {
            if (!params) {
                params = `?${key}=${value}`;
            } else {
                params += `&${key}=${value}`;
            }
        });
        //when using the dropdown list of url with no corresponding netry in contentstack the link default to being
        //commercial and level 1, this may change in the future depending on which entry gets added.
        const url = linkGroup.internal_link[0] ?? {
            page_url: linkGroup?.link_continued,
            regulatory_status: { promotional_or_medical: CMS_PROMOTIONAL_CONTENT },
            access_control: { access_level: AccessLevel.level1 },
        };

        const isPageRestrictedByLinkType = (url: unknown, restrictedPages: RestrictedPageByLinkKeys[]): boolean => {
            const urlKeys = Object.keys(url);
            return urlKeys.some((urlKey: RestrictedPageByLinkKeys) => {
                return restrictedPages.includes(urlKey);
            });
        };

        const getPageAccessLevel = (url): AccessLevel => {
            return url.regulatory_status?.promotional_or_medical === CMS_MEDICAL_CONTENT ||
                isPageRestrictedByLinkType(url, restrictedL3PageLinkTypeList)
                ? AccessLevel.level3
                : (url.access_control?.access_level as AccessLevel) || AccessLevel.level1;
        };

        const link = {
            params,
            anchor_id: linkGroup?.anchor_id || "",
            external: Boolean(url.external_resource_url),
            access_level: getPageAccessLevel(url),
            commercial: url.regulatory_status
                ? url.regulatory_status.promotional_or_medical === CMS_PROMOTIONAL_CONTENT
                : true,
            url: getInternalPageLinkUrl(url),
        };

        if (linkGroup?.link_locale) {
            link.url = link?.url?.startsWith("/")
                ? `/${stripSlash.strip(linkGroup.link_locale)}/${stripSlash.strip(link.url)}`
                : `${stripSlash.strip(linkGroup.link_locale)}/${stripSlash.strip(link.url)}`;
        }
        return link;
    } else if (linkGroup?.file_asset?.url) {
        const accessLevel = fileHelper.getFileAccessLevel(linkGroup?.file_asset?.url);

        return {
            access_level: accessLevel,
            anchor_id: linkGroup?.anchor_id || "",
            external: true,
            commercial: true,
            url: linkGroup?.file_asset?.url,
            isFileAsset: true,
        };
    } else if (linkGroup?.anchor_id) {
        return {
            anchor_id: linkGroup.anchor_id,
            external: false,
            commercial: true,
            url: undefined,
        };
    } else {
        return {
            anchor_id: "",
            external: false,
            commercial: true,
            url: return404
                ? linkGroup?.link_locale
                    ? `/${stripSlash.strip(linkGroup?.link_locale)}/404`
                    : "/404"
                : "",
        };
    }
};

/**
 * transform a CMSAccordionProps instance into props for an accordion
 */
export const mapAccordionProps = (cmsProps: CMSAccordionProps): AccordionProps => {
    return {
        title: cmsProps?.display_title || cmsProps?.title,
        showTitle: cmsProps.display_options?.show_display_title,
        panes: cmsProps?.panes?.map((pane) => ({
            pane: {
                title: pane.pane.pane_title,
                open: pane.pane.open,
                content: pane.pane.content,
            },
        })),
        anchors: cmsProps?.fields?.anchors,
    };
};

/**
 * transform a CMSArticleOverviewProps instance into props for a ArticleOverview
 */
export const mapArticleOverview = (cmsProps: CMSArticleOverviewProps): React.ComponentProps<typeof ArticleOverview> => {
    return {
        title: cmsProps.display_title,
        type: cmsProps.type,
        articles: cmsProps.static_options?.select_items,
        filter: {
            relatedTo: cmsProps.dynamic_options?.relates_to,
            author: cmsProps.dynamic_options?.author,
            articleType: cmsProps.dynamic_options?.type,
            regulatoryStatusFilter: cmsProps.dynamic_options?.regulatory_status_filter,
        },
        displayMode: cmsProps.display_options?.display_mode,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
    };
};

/**
 * transform a CMSButtonProps instance into props for a Button
 */
export const mapButtonProps = (cmsButtonProps: CMSButtonProps): MapButtonProps => {
    return {
        buttonProps: {
            id: cleanCSSIdentifier(`button-${cmsButtonProps?.text?.toLocaleLowerCase()}`),
            variant: mapButtonVariants(cmsButtonProps.variant) as Variants,
            color: mapButtonColors(cmsButtonProps.color),
            flexibleWidth: cmsButtonProps.flexibleWidth,
            noSpacing: cmsButtonProps?.noSpacing,
        },
        link: getLink(cmsButtonProps.link_group),
        fileUrl:
            !cmsButtonProps?.link_group?.external_link &&
            (!cmsButtonProps?.link_group?.internal_link || cmsButtonProps?.link_group?.internal_link?.length === 0) &&
            cmsButtonProps?.link_group?.file_asset?.url,
    };
};

/**
 * transform a CMSCardProps instance into props for a Card
 */
export const mapCardProps = (
    cmsProps: CMSCardProps,
    jnjFullBranded = false,
): React.ComponentProps<typeof GatsbyImageCard> => {
    const textParsingRTE = cmsProps?.card_body?.replace(/&lt;/g, "<").replace(/&gt;/g, ">") || cmsProps?.card_body;

    let titleColor = null;
    let textColor = null;
    let backgroundColor = null;
    let imagePlacement = null;
    if (jnjFullBranded) {
        titleColor = "dark";
        textColor = "text-light";
        backgroundColor = mapBrandedBackgroundColors(
            cmsProps.display_options?.background_color as Colors,
            "transparent",
        );
        imagePlacement = "default";
    } else {
        titleColor = mapColors(
            cmsProps.display_options?.title_color,
            cmsProps.display_options?.variant_type === CMS_CARD_VARIANT_BACKGROUND ? "white" : "dark",
        );
        textColor = mapColors(
            cmsProps.display_options?.text_color,
            cmsProps.display_options?.variant_type === CMS_CARD_VARIANT_BACKGROUND ? "white" : "dark",
        );

        backgroundColor = mapBackgroundColors(cmsProps.display_options?.background_color, "white");
        imagePlacement = cmsProps.display_options?.image_placement || "default";
    }

    return {
        id: cmsProps.id,
        title: cmsProps.display_title,
        card_body: textParsingRTE,
        image: cmsProps.card_image,
        background_color: backgroundColor,
        overlay: cmsProps.display_options?.overlay,
        title_color: titleColor,
        text_color: textColor,
        fullBackground: cmsProps.display_options?.variant_type === CMS_CARD_VARIANT_BACKGROUND,
        imageFocus: cmsProps.display_options?.image_focus?.image_focus || ("center-center" as ImagePosition),
        imagePlacement: imagePlacement as ImagePlacement,
        padding: cmsProps.display_options?.padding || "default",
        call_to_action: cmsProps.call_to_action,
        buttons: cmsProps.call_to_action?.map(({ button }) => button),
    };
};

/**
 * transform a CMSCarouselProps instance into props for a Carousel
 */
export const mapCarouselProps = (cmsProps: CMSCarouselProps): React.ComponentProps<typeof JMCCarousel> => {
    return {
        size: cmsProps?.size || "Medium",
        fullWidth: cmsProps?.full_width,
        interval: {
            automatic: cmsProps?.interval?.automatic,
            speed: cmsProps?.interval?.speed || 5,
        },
        slides: cmsProps?.slides?.map((slide) => ({
            title: slide.title,
            text: slide.text,
            image: slide.image,
            buttons: slide.buttons,
            viewMode: slide.display_options?.view_mode || CarouselViewMode.BACKGROUND,
            titleColor:
                mapColors(slide.display_options?.title_color) ||
                ((slide.display_options?.view_mode || CarouselViewMode.BACKGROUND) === CarouselViewMode.BACKGROUND
                    ? CarouselTextColor.WHITE
                    : CarouselTextColor.DARK),
            textColor:
                mapColors(slide.display_options?.text_color) ||
                ((slide.display_options?.view_mode || CarouselViewMode.BACKGROUND) === CarouselViewMode.BACKGROUND
                    ? CarouselTextColor.WHITE
                    : CarouselTextColor.DARK),
            backgroundColor:
                mapBackgroundColors(slide.display_options?.background_color) || CarouselBackgroundColor.WHITE,
            alignContent: slide.display_options?.align_content || CarouselAlignContent.START,
            alignContentVertically:
                slide.display_options?.align_content_vertically || CarouselAlignContentVertically.CENTER,
            imageFocus: slide.display_options?.image_focus?.image_focus || "center-center",
            overlay: slide.display_options?.overlay,
        })),
    };
};

/**
 * transform a CMSColumnContentAlignment instance into props for column alignment
 */
export const mapColumnContentAlignment = (cmsColumnContentAlignment: CMSColumnContentAlignment): ItemPosition => {
    switch (cmsColumnContentAlignment) {
        default:
        case "Spread":
            return "stretch";
        case "Top":
            return "flex-start";
        case "Bottom":
            return "flex-end";
        case "Center":
            return "center";
    }
};

/**
 * transform a CMSCourseOverviewProps instance into props for a CourseOverview
 */
export const mapCourseOverview = (cmsProps: CMSCourseOverviewProps): React.ComponentProps<typeof CourseOverview> => {
    return {
        title: cmsProps.display_title,
        type: cmsProps.type,
        courses: cmsProps.static_options?.courses,
        filter: {
            status: cmsProps.dynamic_options?.status?.toLowerCase(),
            relatedTo: cmsProps.dynamic_options?.relates_to,
            regulatoryStatusFilter: cmsProps.dynamic_options?.regulatory_status_filter,
        },
        displayMode: cmsProps.display_options?.display_mode,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
    };
};

/**
 * transform a CMSEventOverviewProps instance into props for an EventOverview
 */
export const mapEventOverview = (cmsProps: CMSEventOverviewProps): React.ComponentProps<typeof EventOverview> => {
    return {
        id: cmsProps.id,
        title: cmsProps.display_title,
        type: cmsProps.type,
        events: cmsProps.static_options?.events,
        filter: {
            relatedTo: cmsProps.dynamic_options?.relates_to,
            eventType: cmsProps.dynamic_options?.type,
            regulatoryStatusFilter: cmsProps.dynamic_options?.regulatory_status_filter,
        },
        displayMode: cmsProps.display_options?.display_mode,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
        enableSlider: cmsProps?.enable_slider ?? true,
        displayedFilters: cmsProps.display_options?.display_standard_filters,
    };
};

/**
 * transform a CMSHeadingProps instance into props for a Heading
 */
export const mapHeadingProps = (cmsProps: CMSHeadingProps): React.ComponentProps<typeof Heading> => {
    return {
        title: cmsProps.title,
        imageUrl: cmsProps?.imageurl?.url,
        size: mapHeadingSizes(cmsProps.size),
    };
};

/**
 * transform a CMSHeroProps instance into props for a Hero component
 */
export const mapHeroProps = (cmsProps: CMSHeroProps): React.ComponentProps<typeof Hero> => {
    const imageData = cmsProps?.background_image?.gatsbyImageData
        ? cmsProps?.background_image?.gatsbyImageData
        : cmsProps?.background_image?.url;

    return {
        id: cmsProps?.display_title,
        title: cmsProps?.display_title,
        image: imageData,
        fullWidth: cmsProps?.display_options?.full_width || false,
        heroHeight: cmsProps?.display_options?.hero_height || "auto",
        contentAlignment: (cmsProps?.display_options?.align_content
            ? cmsProps?.display_options?.align_content.toLowerCase()
            : "left") as ContentAlignment,
        contentVerticalAlignment: (cmsProps?.display_options?.align_content_vertically
            ? cmsProps?.display_options?.align_content_vertically?.toLowerCase()
            : "center") as ContentVerticalAlignment,
        titleColor:
            cmsProps?.display_options?.title_color === "--primary" ||
            cmsProps?.display_options?.title_color === "--secondary"
                ? cmsProps?.display_options?.title_color + "-general"
                : cmsProps?.display_options?.title_color,
        textColor:
            cmsProps?.display_options?.body_color === "--primary" ||
            cmsProps?.display_options?.body_color === "--secondary"
                ? cmsProps?.display_options?.body_color + "-general"
                : cmsProps?.display_options?.body_color,
        backgroundColor: imageData
            ? "transparent"
            : mapBackgroundColors(cmsProps?.display_options?.background_color) || "primary",
        imagePosition: cmsProps?.display_options?.image_focus?.image_focus?.toLowerCase() || "center-center",
        overlay: cmsProps?.display_options?.overlay || "none",
    };
};

/**
 * transform a CMSCalloutProps instance into props for a Callout component
 */
export const mapCalloutProps = (cmsProps: CMSHeroProps): React.ComponentProps<typeof Hero> => {
    const imageData = cmsProps?.background_image?.gatsbyImageData
        ? cmsProps?.background_image?.gatsbyImageData
        : cmsProps?.background_image?.url;

    return {
        id: cmsProps?.display_title,
        title: cmsProps?.display_title,
        image: imageData,
        imagePosition: cmsProps?.display_options?.image_focus?.image_focus?.toLowerCase() || "center-center",
        contentAlignment: cmsProps?.display_options?.align_content?.toLowerCase() === "right" ? "end" : "start",
        backgroundColor:
            cmsProps?.display_options?.background_color !== null
                ? mapCalloutColors(cmsProps?.display_options?.background_color)
                : "primary",
        description: cmsProps?.hero_body,
        footer: cmsProps?.call_to_action,
    };
};

/**
 * transform a Hero background color into the right value for a Header Hero component
 */
export const mapHeaderHeroColor = (cmsColor: string): Color => {
    if (Object.values(Color).includes(cmsColor as Color)) {
        return cmsColor as Color;
    }
    return undefined;
};

/**
 * transform a CMSHeroProps instance into props for a Header Hero component
 */
export const mapHeaderHeroProps = (
    cmsProps: CMSHeroProps,
): {
    id: string | JSX.Element;
    title: string | JSX.Element;
    image: string | IGatsbyImageData;
    imagePosition: ImagePosition;
    color: Color;
    variant: Variant;
    size: Size;
} => {
    let size = Size.AUTO;
    switch (cmsProps?.display_options?.hero_height) {
        case "Extra Small":
            size = Size["X-Small"];
            break;
        case "Small":
            size = Size.Small;
            break;
        case "Medium":
            size = Size.Medium;
            break;
        case "Large":
            size = Size.Large;
            break;
    }

    const imageData = cmsProps?.background_image?.gatsbyImageData
        ? cmsProps?.background_image?.gatsbyImageData
        : cmsProps?.background_image?.url;

    const imagePosition = cmsProps?.display_options?.image_focus?.image_focus?.toLowerCase() || "center-center";

    return {
        id: cmsProps?.display_title,
        title: cmsProps?.display_title,
        image: imageData,
        imagePosition: imagePosition as ImagePosition,
        color: (cmsProps?.display_options?.background_color?.toLowerCase() as Color) || undefined,
        variant: cmsProps?.display_options?.variant || undefined,
        size: size,
    };
};

/**
 * transform a CMSImageProps instance into props for a Image
 */
export const mapImageProps = (
    cmsImageProps: CMSImageProps,
    locale: string,
): {
    imageProps: CMSImageProps;
    link: LinkProps;
    fileUrl: string;
} => {
    const { link_group_image, image, ...otherProps } = cmsImageProps;

    let localizedDesc = "";
    if (image?.description?.length > 0) {
        const validatedJson = jsonMapper.checkIfJson(image.description);
        if (validatedJson) {
            localizedDesc = validatedJson[locale];
        }
    }
    if (localizedDesc && localizedDesc.length) {
        image.description = localizedDesc;
    }

    return {
        imageProps: { ...otherProps, image },
        link: getLink(link_group_image, false),
        fileUrl:
            !link_group_image?.external_link &&
            (!link_group_image?.internal_link || link_group_image?.internal_link?.length === 0) &&
            link_group_image?.file_asset?.url,
    };
};

/**
 * transform a CMSMapProps instance into props for a Locator
 */
export const mapLocatorProps = (cmsProps: CMSMapProps): React.ComponentProps<typeof Locator> => {
    const myTag = cmsProps?.uid;
    return {
        tag: myTag,
        distanceUnit: cmsProps?.distance_unit ? cmsProps?.distance_unit : "km",
    };
};

/**
 * transform a CMSMaterialOverview instance into props for a Related materials
 */
export const mapMaterialOverview = (
    cmsProps: CMSMaterialOverviewProps,
): React.ComponentProps<typeof MaterialOverview> => {
    return {
        title: cmsProps.display_title,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
        type: cmsProps.type,
        materials: cmsProps.static_options?.select_items,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        displayMode: cmsProps.display_options?.display_mode,
        filter: {
            relatedTo: cmsProps.dynamic_options?.relates_to,
            materialType: cmsProps.dynamic_options?.type,
            targetAudience: cmsProps.dynamic_options?.target_audience,
            regulatoryStatusFilter: cmsProps.dynamic_options?.regulatory_status_filter,
        },
    };
};

/**
 * transform a CMSMediaOverviewProps instance into props for a MediaOverview
 */
export const mapMediaOverview = (cmsProps: CMSMediaOverviewProps): React.ComponentProps<typeof MediaOverview> => {
    return {
        title: cmsProps.display_title,
        type: cmsProps.type,
        media: cmsProps.static_options?.media,
        relatedTo: cmsProps.dynamic_options?.relates_to,
        displayMode: cmsProps.display_options?.display_mode,
        includeVideo: cmsProps.dynamic_options?.include_video,
        includeAudio: cmsProps.dynamic_options?.include_audio,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
        regulatoryStatusFilter: cmsProps.dynamic_options?.regulatory_status_filter,
    };
};

/**
 * transform an array of CMSMenuItem into props for Menu Items
 */
export const mapMenuItems = (cmsMenuItems: [CMSMenuItem]): MenuItem[] => {
    return cmsMenuItems.map((cmsItem: CMSMenuItem): MenuItem => {
        return {
            menu_item: {
                icon: cmsItem.menu_item.icon,
                label: cmsItem.menu_item.label,
                link: {
                    url: cmsItem.menu_item.link.length
                        ? cmsItem.menu_item.link[0].url
                        : cmsItem.menu_item.external_link.href,
                },
            },
        };
    });
};

/**
 * transform a CMSMenuItem instance into props for a onetrust integrated form
 */
export const mapOnetrustWebForm = (cmsProps: CMSOnetrustFormProps): React.ComponentProps<typeof OneTrustWebForm> => {
    return {
        id: cmsProps?.onetrust_id,
        buttonId: cmsProps?.onetrust_button_id,
        thankYouUrl: (cmsProps?.thank_you_page[0] as onetrustPageReference)?.page_url,
    };
};

/**
 * transform a CMSPodcastOverviewProps instance into props for a PodcastOverview
 */
export const mapPodcastOverview = (cmsProps: CMSPodcastOverviewProps): React.ComponentProps<typeof PodcastOverview> => {
    return {
        title: cmsProps?.display_title?.length ? cmsProps.display_title : cmsProps.title,
        type: cmsProps.type,
        podcasts: cmsProps.static_options?.select_items,
        filter: {
            relatedTo: cmsProps.dynamic_options?.relates_to,
        },
        displayMode: cmsProps.display_options?.display_mode,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
    };
};

/**
 * transform a CMSProductOverviewProps instance into props for a ProductOverview
 */
export const mapProductOverview = (cmsProps: CMSProductOverviewProps): React.ComponentProps<typeof ProductOverview> => {
    return {
        title: cmsProps.display_title,
        type: cmsProps.type,
        products: cmsProps.static_options?.select_items,
        relatedTo: cmsProps.dynamic_options?.relates_to,
        displayMode: cmsProps.display_options?.display_mode,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
    };
};

/**
 * transform a CMSPubmedSearchProps instance into props for a PubmedSearch
 */
export const mapPubmedSearch = (cmsProps: CMSPubmedSearchProps): React.ComponentProps<typeof PubmedSearch> => {
    return {
        title: cmsProps?.title,
        displayTitle: cmsProps?.shouldDisplayTitle,
        description: cmsProps?.description,
    };
};

/**
 * transform a CMSScientificPublicationOverviewProps instance into props for a ScientificPublicationOverview
 */
export const mapScientificPublicationOverview = (
    cmsProps: CMSScientificPublicationOverviewProps,
): React.ComponentProps<typeof ScientificPublicationOverview> => {
    return {
        title: cmsProps?.display_title?.length ? cmsProps.display_title : cmsProps.title,
        type: cmsProps.type,
        publications: cmsProps.static_options?.select_items,
        filter: {
            relatedTo: cmsProps.dynamic_options?.relates_to,
            author: cmsProps.dynamic_options?.author,
            type: cmsProps.dynamic_options?.type,
        },
        displayMode: cmsProps.display_options?.display_mode,
        showDisplayTitle: cmsProps.display_options?.show_display_title,
        linkToFullOverviewPage: cmsProps.display_options?.enable_link_to_full_overview_page,
        numberOfResults: cmsProps.dynamic_options?.maximum_number_of_results,
    };
};

export const mapSection = (
    cmsProps: CMSSectionProps,
    defaultLanguageTextDirection: "ltr" | "rtl",
): {
    backgroundColor: string;
    title: string;
    showTitle: boolean;
    textDirection: string;
    audiences: CMSAudienceProps[];
    anchorId: string;
} => {
    const reverseTextDirection = defaultLanguageTextDirection == "ltr" ? "rtl" : "ltr";
    return {
        backgroundColor: colorMap.get(cmsProps?.background_color?.toLowerCase()),
        title: cmsProps?.section_title,
        showTitle: cmsProps?.show_title,
        textDirection: cmsProps?.reverse_text_direction ? reverseTextDirection : defaultLanguageTextDirection,
        audiences: cmsProps?.audiences,
        anchorId: cmsProps?.section_anchor_id,
    };
};

/**
 * transform a CMSTabsProps instance into props for a Tab component
 */
export const mapTabProps = (cmsProps: CMSTabsProps): React.ComponentProps<typeof Tabs> => {
    return {
        title: cmsProps?.display_title || cmsProps?.title,
        showTitle: cmsProps.display_options?.show_display_title,
        panes: cmsProps?.panes?.map((pane) => ({
            pane: {
                title: pane.pane.pane_title,
                content: pane.pane.content,
            },
        })),
        anchors: cmsProps?.fields?.anchors,
    };
};

/**
 * transform a CMSTypographyProps instance into props for a Typography component
 */
export const mapTypographyProps = (cmsProps: CMSTypographyProps): React.ComponentProps<typeof Typography> => {
    return {
        text: cmsProps.text,
        // TODO map with better color/variant names, this approach can be reused for other components like buttons, etc
        color: cmsProps.color,
        variant: cmsProps.variant,
    };
};

export default {
    getLink,
    mapAccordionProps,
    mapArticleOverview,
    mapButtonProps,
    mapCalloutProps,
    mapCalloutColors,
    mapCardProps,
    mapCarouselProps,
    mapColumnContentAlignment,
    mapCourseOverview,
    mapEventOverview,
    mapHeadingProps,
    mapHeroProps,
    mapHeaderHeroColor,
    mapHeaderHeroProps,
    mapImageProps,
    mapLocatorProps,
    mapMaterialOverview,
    mapMediaOverview,
    mapMenuItems,
    mapOnetrustWebForm,
    mapPodcastOverview,
    mapProductOverview,
    mapPubmedSearch,
    mapScientificPublicationOverview,
    mapSection,
    mapTabProps,
    mapTypographyProps,
};
