import { StylesConfig } from "react-select";
import { SelectPropTypes, ReactSelectOption } from "./Select";

/**
 * Custom implementation of react-select defaultAriaLiveMessages
 * to provide translation to aria messages.
 *
 * https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/accessibility/index.ts#L103
 *
 * You can use the invertedSelection parameter if the Select component is reading the wrong state.
 * This can happen if you are storing the selected values in a state, then change in status happens before
 * the aria labels are read.
 *
 * @param t - Translation function
 * @param invertedSelection - Inverts the message for the selected state of an option
 *
 */
export const getCustomAriaLabels = (t: (key: string, params?: any) => string, invertedSelection = false) => {
    return {
        guidance: (props: {
            [x: string]: any;
            isSearchable?: any;
            isMulti?: any;
            tabSelectsValue?: any;
            context?: any;
            isInitialFocus?: any;
        }) => {
            const { isSearchable, isMulti, tabSelectsValue, context, isInitialFocus } = props;

            switch (context) {
                case "menu":
                    return t(
                        "Use Up and Down to choose options, press Enter to select the currently focused option, press Escape to exit the menu",
                        { ns: "accessibility" },
                    ).concat(
                        tabSelectsValue
                            ? "".concat(
                                  ", ",
                                  t("press Tab to select the option and exit the menu", { ns: "accessibility" }),
                              )
                            : "",
                    );
                case "input":
                    return isInitialFocus
                        ? ""
                              .concat(t("Press Down to open the menu", { ns: "accessibility" }))
                              .concat(
                                  isSearchable
                                      ? "".concat(", ", t("type to refine list", { ns: "accessibility" }))
                                      : "",
                              )
                              .concat(
                                  isMulti
                                      ? "".concat(
                                            ", ",
                                            t("press left to focus selected values", { ns: "accessibility" }),
                                        )
                                      : "",
                              )
                        : "";
                case "value":
                    return t(
                        "Use left and right to toggle between focused values, press Backspace to remove the currently focused value",
                        { ns: "accessibility" },
                    );
                default:
                    return "";
            }
        },
        onChange: (props: {
            action: any;
            label?: "";
            labels?: any[];
            isDisabled: any;
            value: ReactSelectOption;
            options: ReactSelectOption[];
            option?: ReactSelectOption;
        }) => {
            const { action, label = "", isDisabled, value, options, option } = props;
            const ariaLabel = value?.ariaLabel || label;
            const ariaLabels = options?.map((option) => option?.ariaLabel || option?.label);
            let selected = isDisabled || option?.isSelected;
            selected = invertedSelection ? !selected : selected;

            switch (action) {
                case "deselect-option":
                case "pop-value":
                case "remove-value":
                    return t("option {{LABEL}}, deselected.", { LABEL: ariaLabel, ns: "accessibility" });
                case "clear":
                    return t("All selected options have been cleared.", { ns: "accessibility" });
                case "initial-input-focus":
                    return ariaLabels.length > 0
                        ? t("options {{LABEL}}, selected.", { LABEL: ariaLabels.join(","), ns: "accessibility" })
                        : t("option {{LABEL}}, selected.", { LABEL: ariaLabels.join(","), ns: "accessibility" });
                case "select-option":
                    return selected
                        ? t("option {{LABEL}} is disabled. Select another option.", {
                              LABEL: ariaLabel,
                              ns: "accessibility",
                          })
                        : t("option {{LABEL}}, selected.", { LABEL: ariaLabel, ns: "accessibility" });

                default:
                    return "";
            }
        },
        onFocus: (props: {
            context: any;
            focused: any;
            options: any;
            label: any;
            selectValue: any;
            isDisabled: any;
            isSelected: any;
            isAppleDevice: any;
        }) => {
            const { context, focused, options, label, selectValue, isDisabled, isSelected, isAppleDevice } = props;
            const ariaLabel = focused.ariaLabel || label;

            const getArrayIndex = (arr: string[], item: string) => {
                if (arr && arr.length) {
                    return t("{{LABEL}} of {{LENGTH}}", {
                        LABEL: arr.indexOf(item) + 1,
                        LENGTH: arr.length,
                        ns: "accessibility",
                    });
                } else {
                    return "";
                }
            };

            if (context === "value" && selectValue) {
                return t("value {{LABEL}} focused, {{INDEX}}.", {
                    LABEL: ariaLabel,
                    INDEX: getArrayIndex(selectValue, focused),
                    ns: "accessibility",
                });
            }
            if (context === "menu" && isAppleDevice) {
                let status = "";
                if (isSelected) {
                    status = isDisabled
                        ? t("{{LABEL}} selected and disabled", { LABEL: ariaLabel, ns: "accessibility" })
                        : t("{{LABEL}} selected", { LABEL: ariaLabel, ns: "accessibility" });
                } else {
                    status = isDisabled
                        ? t("{{LABEL}} disabled", { LABEL: ariaLabel, ns: "accessibility" })
                        : t("{{LABEL}}", { LABEL: ariaLabel, ns: "accessibility" });
                }

                return t("{{STATUS}}, {{INDEX}}.", {
                    STATUS: status,
                    INDEX: getArrayIndex(options, focused),
                    ns: "accessibility",
                });
            }
            return "";
        },
        onFilter: (props: { inputValue: any; resultsMessage: any }) => {
            const { inputValue, resultsMessage } = props;

            if (inputValue) {
                return t("{{RESULTS}} for search term {{INPUT}}.", {
                    RESULTS: resultsMessage,
                    INPUT: inputValue,
                    ns: "accessibility",
                });
            } else return resultsMessage;
        },
    };
};

export type FilterSelectOptionType = {
    label: string;
    value: string;
    isSelected: boolean;
};

export type ColorMappings = {
    primary: string;
    secondary: string;
};

// TODO: Rebranding: Delete after rebranding
const SECONDARY_COLOR = "var(--secondary)";

const borderColorMapping: ColorMappings = {
    primary: "var(--select-border-color, #858585)",
    secondary: SECONDARY_COLOR,
};

// TODO: Rebranding: Delete after rebranding
const placeholderColorMapping: ColorMappings = {
    primary: "var(--select-color-placeholder, #858585)",
    secondary: SECONDARY_COLOR,
};

// TODO: Rebranding: Delete after rebranding
const dropdownColorMapping: ColorMappings = {
    primary: "var(--select-color-dropdown, #606060)",
    secondary: SECONDARY_COLOR,
};

// When dropdowns are used as filters, there are some differences in the styles
export const getFilterStyle = (): StylesConfig<FilterSelectOptionType, false> => {
    return {
        control: (provided: any, state: any) => ({
            ...provided,
            borderWidth: state?.hasValue ? "2px" : "1px",
            backgroundColor: "var(--white)",
            borderColor: state?.hasValue ? "var(--select-border-color-with-option)" : "var(--select-border-color)",
            minHeight: "var(--select-height)",
            "&:hover": {
                backgroundColor: "var(--white)",
                borderColor: "var(--select-border-color-hover)",
            },
        }),
        placeholderOptions: { color: "var(--select-color-dropdown)" },
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            fontWeight: state?.hasValue ? "500" : "400",
        }),
    };
};

// Filters used in JMC ControlHeader component behaves differently, and needs a different style
// TODO: All filters in both projects should work the same way. This can be deleted after that and use getFilterStyle
export const getJMCFilterStyle = (): StylesConfig<FilterSelectOptionType, false> => {
    const styles = getFilterStyle();

    return {
        ...styles,
        control: (provided: any, state: any) => ({
            ...provided,
            ...styles?.control({ minHeight: "var(--select-height)", maxHeight: "none" }, state),
        }),
        container: { maxHeight: "none", height: "100%", minHeight: "var(--select-height)" },
    };
};

// Style for standard dropdowns
export const getStyle = (props: SelectPropTypes): StylesConfig<FilterSelectOptionType, false> => {
    const {
        color = "primary", // TODO: Rebranding: Not needed after rebranding
        error,
        control,
        placeholderOptions,
        valueContainer,
        option,
        menu,
        menuList,
        isMulti,
        hoverColor,
        container,
        jnjFullBranded,
    } = props;
    const borderColor =
        control?.borderColor ?? (jnjFullBranded ? "var(--select-border-color)" : borderColorMapping[color]);

    return {
        control: (provided: any, state: any) => {
            let controlBackground = control?.backgroundColor;
            if (jnjFullBranded) {
                controlBackground =
                    control?.backgroundColor ??
                    (state?.isDisabled || state?.menuIsOpen ? "var(--interactive-09)" : "var(--white)");
            } else {
                controlBackground = error
                    ? "var(--error-100)"
                    : controlBackground
                    ? controlBackground === "transparent" && state.menuIsOpen
                        ? "var(--white)"
                        : controlBackground
                    : provided.backgroundColor;
            }

            const styles = {
                ...provided,
                minHeight: control?.minHeight ?? (jnjFullBranded ? "100%" : provided.minHeight),
                maxHeight: control?.maxHeight ?? (jnjFullBranded ? "var(--select-height)" : provided.maxHeight),
                height: jnjFullBranded ? "100%" : "var(--select-height)",
                outline: state.isFocused ? "5px auto -webkit-focus-ring-color" : "0 !important",
                cursor: control?.cursor ?? (jnjFullBranded ? "pointer" : "default"),
                backgroundColor: controlBackground,
                borderColor: error
                    ? "var(--select-color-error)"
                    : state?.isDisabled
                    ? "var(--select-color-disabled)"
                    : borderColor,
                borderWidth: control?.borderWidth ?? "1px",
                boxShadow: control?.boxShadow ? control?.boxShadow : "none",
                boxSizing: jnjFullBranded ? "border-box" : "content-box",
                borderTop: state.menuIsOpen ? control?.borderTop : "default",
                borderTopLeftRadius: control?.borderTopLeftRadius
                    ? state.menuIsOpen
                        ? 0
                        : control?.borderTopLeftRadius
                    : "var(--select-border-top-left-radius, 4px)",
                borderTopRightRadius: control?.borderTopRightRadius
                    ? state.menuIsOpen
                        ? 0
                        : control?.borderTopRightRadius
                    : "var(--select-border-top-right-radius, 4px)",
                borderBottomLeftRadius: control?.borderBottomLeftRadius
                    ? control?.borderBottomLeftRadius
                    : state.menuIsOpen
                    ? 0
                    : "var(--select-border-bottom-left-radius, 4px)",
                borderBottomRightRadius: control?.borderBottomRightRadius
                    ? control?.borderBottomRightRadius
                    : state.menuIsOpen
                    ? 0
                    : "var(--select-border-bottom-right-radius, 4px)",
                color: "var(--select-color-default)",
                padding: "var(--select-control-padding, 0)",
                "&:hover": {
                    borderColor: jnjFullBranded ? "var(--select-border-color-hover)" : borderColor,
                    backgroundColor: jnjFullBranded
                        ? control?.backgroundColor ?? "var(--interactive-09)"
                        : controlBackground,
                },
            };

            let returnStyles = control?.height
                ? { ...styles, height: control?.height, minHeight: control?.height }
                : { ...styles };
            returnStyles =
                props.tagColor === "default"
                    ? { ...returnStyles, color: "var(--text-dark)" }
                    : { ...returnStyles, color: "var(--white)" };

            if (control && typeof control === "function") {
                returnStyles = { ...returnStyles, ...control(provided, state) };
            }
            return returnStyles;
        },
        singleValue: (provided: any, state: any) => {
            let singleValueColor;
            if (jnjFullBranded) {
                singleValueColor = state?.isDisabled ? "var(--text-03)" : "var(--select-color-dropdown)";
            } else {
                singleValueColor = error ? "var(--select-color-error)" : "var(--text-dark)";
            }

            return {
                ...provided,
                color: singleValueColor,
                margin: 0,
            };
        },
        placeholder: (provided: any, state: any) => {
            const menuIsOpen = state?.selectProps?.menuIsOpen;
            let placeholderColor;
            if (jnjFullBranded) {
                placeholderColor = placeholderOptions?.color ?? "var(--select-color-placeholder)";
            } else {
                placeholderColor = placeholderOptions?.color
                    ? !menuIsOpen
                        ? placeholderOptions?.color
                        : placeholderColorMapping[color]
                    : placeholderColorMapping[color];
            }

            const styles = {
                ...provided,
                position: "initial",
                transform: "initial",
                color: placeholderColor,
            };
            return placeholderOptions?.left ? { ...styles, left: placeholderOptions.left } : styles;
        },
        menu: (provided: any) => {
            let styles = {
                ...provided,
                border: "var(--select-menu-border-width) solid var(--select-border-color)",
                borderRadius: isMulti
                    ? "0 0 var(--select-border-bottom-right-radius) var(--select-border-bottom-left-radius)"
                    : menu?.borderBottomLeftRadius && menu?.borderBottomRightRadius
                    ? "var(--select-border-top-left-radius) var(--select-border-top-right-radius) 0 0"
                    : "var(--select-border-radius)",

                borderColor: menu?.borderColor ?? borderColor,
                boxShadow: menu?.boxShadow ? menu?.boxShadow : "var(--select-menu-shadow)",
                margin: "var(--select-menu-margin)",
                zIndex: menu?.zIndex ? menu?.zIndex : "var(--layer-higher-priority)",
                borderBottom:
                    menu?.borderBottom !== undefined
                        ? menu?.borderBottom
                        : "var(--select-menu-border-width) solid var(--select-border-color)",
                padding: menu?.padding ? menu?.padding : 0,
                color: "var(--select-color-menu)",
                width: jnjFullBranded ? "max-content" : "100%",
                minWidth: jnjFullBranded ? "100%" : "auto",
                cursor: menu?.cursor ?? (jnjFullBranded ? "pointer" : "default"),
            };
            styles = menu?.width ? { ...styles, width: menu?.width } : styles;
            styles = menu?.top ? { ...styles, top: menu?.top } : styles;
            styles = menu?.left ? { ...styles, left: menu?.left } : styles;
            styles = menu?.right ? { ...styles, right: menu?.right } : styles;

            return styles;
        },
        menuList: (provided: any) => {
            const styles = {
                ...provided,
                paddingTop: menuList?.paddingTop ?? "var(--select-menu-list-padding-top)",
                paddingBottom: menuList?.paddingBottom ?? "var(--select-menu-list-padding-bottom)",
                cursor: menuList?.cursor ?? (jnjFullBranded ? "pointer" : "default"),
            };
            return menuList?.maxHeight ? { ...styles, maxHeight: menuList?.maxHeight } : styles;
        },
        valueContainer: (provided: any, state: any) => {
            let styles = {
                ...provided,
                padding: "var(--select-value-container-padding, 2px 8px)",
                cursor: valueContainer?.cursor ?? (jnjFullBranded ? "pointer" : "default"),
                height: jnjFullBranded ? "100%" : "auto",
            };
            styles = control?.height ? { ...styles, height: control.height } : styles;
            if (valueContainer && typeof valueContainer === "function") {
                styles = { ...valueContainer(provided, state), ...styles };
            }
            return styles;
        },
        input: (provided: any) => ({
            ...provided,
            padding: 0,
            margin: 0,
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        container: (provided: any) => ({
            ...provided,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: container?.width ?? (jnjFullBranded ? "auto" : "100%"),
            height: container?.height ?? "var(--select-height)",
            boxSizing: jnjFullBranded ? "border-box" : "content-box",
            maxHeight: container?.maxHeight ?? "var(--select-height)",
            minHeight: container?.minHeight ?? "none",
        }),
        dropdownIndicator: (provided: any, state: { selectProps: { menuIsOpen: boolean }; isDisabled: boolean }) => {
            let indicatorColor;
            if (jnjFullBranded) {
                indicatorColor =
                    placeholderOptions?.color ??
                    (state?.isDisabled ? "var(--text-03)" : "var(--select-color-dropdown)");
            } else {
                indicatorColor = placeholderOptions?.color
                    ? state.selectProps.menuIsOpen
                        ? "var(--primary)"
                        : placeholderOptions?.color
                    : dropdownColorMapping[color];
            }

            return {
                ...provided,
                transition: "all .2s ease",
                transform: state.selectProps.menuIsOpen ? "rotateX(180deg)" : null,
                color: indicatorColor,
                padding: jnjFullBranded ? "0" : "6px",
                "&:hover": {
                    color: hoverColor ? (state.selectProps.menuIsOpen ? "var(--primary)" : hoverColor) : "default",
                },
            };
        },
        option: (provided: any, state: { isSelected: boolean; isActive: boolean; isFocused: boolean }) => ({
            ...provided,
            fontSize: valueContainer?.fontSize ? valueContainer.fontSize : "var(--select-option-font-size)",
            backgroundColor: state.isFocused
                ? option?.backgroundColor || (jnjFullBranded ? "var(--background-04)" : `var(--${color}-300)`)
                : "none",
            color:
                ((state.isSelected && state.isFocused) || state.isFocused || state.isActive) && !jnjFullBranded
                    ? "var(--white)"
                    : "inherit",
            marginTop: option?.marginTop || "var(--select-option-margin-top)",
            marginInline: option?.marginInline || 0,
            height: option?.height || "auto",
            width: option?.width || "100%",
            fontWeight: option?.fontWeight || "var(--select-option-font-weight)",
            lineHeight: option?.lineHeight || "var(--select-option-line-height)",
            padding: option?.padding || "var(--select-option-padding)",
            borderRadius: "var(--select-option-border-radius)",
            cursor: option?.cursor ?? (jnjFullBranded ? "pointer" : "default"),
            "&:hover": {
                backgroundColor:
                    option?.backgroundColor || (jnjFullBranded ? "var(--background-04)" : `var(--${color})`),
                color: jnjFullBranded ? "inherit" : "var(--white)",
            },
            "&:active": {
                backgroundColor:
                    option?.backgroundColor || (jnjFullBranded ? "var(--background-04)" : `var(--${color}-400)`),
                color: jnjFullBranded ? "inherit" : "var(--white)",
            },
            "&:focus": {
                backgroundColor:
                    option?.backgroundColor || (jnjFullBranded ? "var(--background-04)" : `var(--${color}-300)`),
                color: jnjFullBranded ? "inherit" : "var(--white)",
                outline: "none",
            },
            "&:first-of-type": {
                marginTop: 0,
            },
        }),
    };
};
