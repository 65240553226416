import analyticstracker from "@jmc/analyticstracker";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction";
import { EventTypes } from "@jmc/core/src/types";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";
import React, { useEffect, useRef } from "react";

export interface PropTypes {
    children?: JSX.Element | JSX.Element[];
    /* name of the notification only when it is different then type */
    name?: string;
    /* position of the notification */
    position?: number;
    /* type of the message showed in the notification (external link disclaimer, medical content disclaimer,
       medical event disclaimer, anonymous login disclaimer, login disclaimer, email verify notification,
       session expired, error-message, etc...)
    */
    type: string;
    /* trigger of notification e.g. time, user action, system error */
    trigger: "time" | "user-action" | "error";
    /* specific action performed on the notification (e.g. ok, cancel, login anonymously, login ) or dissapeared over time */
    action?: string;
    /* whether to check for the visibility of Notification or not */
    checkVisibility?: boolean;
}

export const NotificationImpression: React.FunctionComponent<PropTypes> = (props: PropTypes) => {
    const { interacted } = useCookieInteraction();
    const { children, name = "", position = 1, type, trigger, action = null, checkVisibility = true } = props;
    const target = useRef();
    const notificationContainer = useRef<HTMLDivElement>(null);
    const inViewport = useOnScreen(notificationContainer);
    const { jnjFullBranded } = useJnjBranding();

    // tracking impression
    useEffect(() => {
        if (inViewport && interacted) {
            setTimeout(() => {
                analyticstracker().trackImpression(EventTypes.NOTIFICATION_IMPRESSION, {
                    checkVisibility: checkVisibility,
                });
            }, 1000);
        }
    }, [inViewport, interacted]);

    // track action
    useEffect(() => {
        if (!action) {
            return;
        }
        analyticstracker().trackInteraction(
            {
                currentTarget: target.current,
            },
            {
                changeEvent: EventTypes.NOTIFICATION_CLICK,
                addData: { info: { action } },
            },
        );
    }, [action]);

    return jnjFullBranded ? (
        <div>
            <div
                role="none"
                data-tracking-event={EventTypes.NOTIFICATION_IMPRESSION}
                data-tracking-info={JSON.stringify({ name, position, type, trigger })}
                data-test-id={`NotificationImpression.${type}.${trigger}`}
                ref={notificationContainer}
            >
                {children}
            </div>
            <div
                ref={target}
                data-tracking-event={EventTypes.NOTIFICATION_CLICK}
                data-tracking-info={JSON.stringify({
                    name,
                    position,
                    type,
                    trigger: type === "error-message" ? "time" : trigger,
                })}
            ></div>
        </div>
    ) : (
        <>
            <div
                role="none"
                data-tracking-event={EventTypes.NOTIFICATION_IMPRESSION}
                data-tracking-info={JSON.stringify({ name, position, type, trigger })}
                data-test-id={`NotificationImpression.${type}.${trigger}`}
                ref={notificationContainer}
            >
                {children}
            </div>
            <div
                ref={target}
                data-tracking-event={EventTypes.NOTIFICATION_CLICK}
                data-tracking-info={JSON.stringify({
                    name,
                    position,
                    type,
                    trigger: type === "error-message" ? "time" : trigger,
                })}
            ></div>
        </>
    );
};
